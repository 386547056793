define("discourse/plugins/discourse-code-review/discourse/templates/connectors/user-preferences-notifications/notify-code-review", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "code_review.title"}}</label>
    <div class="controls">
      <PreferenceCheckbox
        @labelKey="code_review.notify_on_approval"
        @checked={{this.notifyOnCodeReviews}}
      />
    </div>
  </div>
  */
  {
    "id": "u/ySpysz",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"code_review.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@labelKey\",\"@checked\"],[\"code_review.notify_on_approval\",[30,0,[\"notifyOnCodeReviews\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-code-review/discourse/templates/connectors/user-preferences-notifications/notify-code-review.hbs",
    "isStrictMode": false
  });
});